<template>
  <q-no-ssr>
    <q-layout>
      <q-page-container>
        <q-page
          :style-fn="myTweak"
          :class="{
            'bg-f5':
              $route.name === 'ProfilePage' ||
              $route.name === 'ResultPage' ||
              $route.name === 'BusinessProfilePage' ||
              $route.name === 'ReviewPage',
          }"
        >
          <div
            class="row justify-between items-center full-width unAuth-logo-wrapper absolute"
          >
            <img
              class="logo cursor-pointer"
              loading="lazy"
              src="../assets/images/logo.svg"
              alt="Akwadlogo"
              @click="goToHomePage"
            />
          </div>
          <div class="row" style="height: 100vh">
            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <Component :is="Component" />
              </transition>
            </router-view>
            <section id="bg-half" class="col-5 gt-sm">
              <div class="fit">
                <q-img
                  src="/login-bottom.png"
                  height="100%"
                  fit="contain"
                  position="bottom"
                  alt="logo"
                />
              </div>
            </section>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-no-ssr>
</template>

<script setup>
import { useMeta, QNoSsr, QLayout, QPageContainer, QPage } from 'quasar';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const goToHomePage = () => {
  router.push({ name: 'HomePage' });
};
useMeta(route.meta)
const myTweak = (offset) => {
  // "offset" is a Number (pixels) that refers to the total
  // height of header + footer that occupies on screen,
  // based on the QLayout "view" prop configuration

  // this is actually what the default style-fn does in Quasar
  return { minHeight: offset ? `calc(100vh - ${offset}px)` : '100vh' };
};
</script>
